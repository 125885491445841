import React, { CSSProperties } from 'react';
import './lozenge.scss';
import { getClasses } from '@/helpers/classes';

export enum Theme {
  Standard = 'standard',
  Error = 'error',
  Peach = 'peach',
  Grey = 'grey',
  Teal = 'teal',
}

export enum Indicator {
  Standard = 'standard',
  Alert = 'alert',
  White = 'white',
  DarkPink = 'dark-pink',
  Teal = 'teal',
}

export enum Border {
  Teal = 'teal',
}

type Props = {
  title: string;
  theme?: Theme;
  indicator?: Indicator;
  labelVisible?: boolean;
  border?: Border;
  textColor?: CSSProperties['color'];
};

export default function Lozenge({
  title,
  theme = Theme.Standard,
  indicator,
  border,
  labelVisible = true,
  textColor,
}: Props) {
  const classRecords = {
    'ds-lozenge': true,
    [`ds-lozenge--theme-${theme}`]: true,
    'ds-lozenge--indicator': Boolean(indicator),
    [`ds-lozenge--indicator--${indicator}`]: Boolean(indicator),
    'ds-lozenge--label-hidden': !labelVisible,
    [`ds-lozenge--border--${border}`]: Boolean(border),
  };

  const classes = getClasses(classRecords);

  return (
    <span className={classes} title={title} style={{ color: textColor }}>
      {title}
    </span>
  );
}
