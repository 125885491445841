import React, { PropsWithChildren } from 'react';
import './tag.scss';

export enum TagColor {
  PEACH = 'peach',
  TEAL = 'teal',
  GREY = 'grey',
  HIGHLIGHT = 'highlight',
}

export type Props = PropsWithChildren<{
  title: string;
  color?: TagColor;
  minWidth?: React.CSSProperties['minWidth'];
  maxWidth?: React.CSSProperties['maxWidth'];
}>;

export default function Tag({ title, color = TagColor.PEACH, minWidth = 'auto', maxWidth = 'none', children }: Props) {
  return (
    <span className={`ds-tag ds-tag--${color}`} title={title} style={{ minWidth, maxWidth }}>
      {children}
    </span>
  );
}
