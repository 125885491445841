import React, { useReducer, useEffect } from 'react';
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';

export enum SyncState {
  NONE = 'none',
  SYNCING = 'syncing',
  SYNCED = 'synced',
  ERROR = 'error',
}

type Args<QData, MVariables, MContext> = {
  mutation: UseMutationResult<void, unknown, MVariables, MContext>;
  query: UseQueryResult<QData>;
  syncTime?: number;
  lagTime?: number;
};

export const useSyncState = <TData, MVariables, MContext>({
  mutation,
  query,
  /** Time to set the 'synced' state for */
  syncTime = 1000,
  /** Time to wait After new query data before setting the 'synced' state */
  lagTime = 500,
}: Args<TData, MVariables, MContext>) => {
  const [_, rerender] = useReducer(() => ({}), {});
  const msSinceLastFetch = Date.now() - query.dataUpdatedAt;

  /**
   * Schedule re-renders when the syncTime/lagTime expire..
   */
  useEffect(() => {
    const lagTimeout = setTimeout(() => {
      rerender();
    }, lagTime);

    const syncTimeout = setTimeout(() => {
      rerender();
    }, lagTime + syncTime);

    return () => {
      clearTimeout(lagTimeout);
      clearTimeout(syncTimeout);
    };
  }, [query.dataUpdatedAt]);

  const isLoading = mutation.isPending || query.isFetching;
  const isError = mutation.isError || query.isError;

  let status = SyncState.NONE;
  if (isLoading || msSinceLastFetch < lagTime) status = SyncState.SYNCING;
  if (!isLoading && msSinceLastFetch >= lagTime && msSinceLastFetch <= lagTime + syncTime) status = SyncState.SYNCED;
  if (isError) status = SyncState.ERROR;

  return status;
};
