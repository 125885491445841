import React from 'react';
import TrendBlockLoading from './TrendBlockLoading';
import TrendBlockError from './TrendBlockError';
export { TrendBlockLoading, TrendBlockError };
import './trendBlock.scss';

export enum TrendBlockDirection {
  UP = 'up',
  DOWN = 'down',
}

type Props = {
  stats: [number, number, number, number];
  heading: string;
  direction: TrendBlockDirection;
  diff: number;
  timeRange: string;
  headingTag?: 'h2' | 'h3' | 'h4' | 'h5';
};

export function TrendBlock({ stats, diff, heading, timeRange, direction, headingTag = 'h2' }: Props) {
  const HeadingTag = headingTag;
  const currentStat = stats[3];
  return (
    <div className={`ds-trend-block ds-trend-block--${direction}`}>
      <HeadingTag className="ds-trend-block__heading">{heading}</HeadingTag>
      <div className="ds-trend-block__line">
        {stats.slice(0, 3).map((s, index) => (
          <span key={index} className={`ds-trend-block__dot ds-trend-block__dot--${index + 1}`}>
            <label className="ds-trend-block__dot__label">
              {s.toLocaleString(undefined, { maximumFractionDigits: 1 })}
            </label>
          </span>
        ))}
        <div className="ds-trend-block__current-dot" />
      </div>

      <div className="ds-trend-block__wrapper">
        <span className="ds-trend-block__current-stats">{currentStat}</span>
        <p className="ds-trend-block__desc">
          {direction} {diff} from last {timeRange}
        </p>
      </div>
    </div>
  );
}
