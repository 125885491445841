import React, { PropsWithChildren } from 'react';
import Spinner, { SpinnerTheme } from '../spinner';
import './button.scss';
import { getClasses } from '@/helpers/classes';

export enum Theme {
  ALL_PINK = 'all-pink',
  GREY_BORDER = 'grey-border',
  BLACK_BORDER = 'black-border',
  PINK_BORDER = 'pink-border',
  RED_BORDER = 'red-border',
  ALL_RED = 'all-red',
}

export enum IconPosition {
  LEFT,
  RIGHT,
}

export type Props = PropsWithChildren<{
  theme: Theme;
  onClick?: () => void;
  href?: string;
  label?: string;
  type?: 'button' | 'submit';
  semiboldText?: boolean;
  darkText?: boolean;
  largeText?: boolean;
  fullWidth?: boolean;
  narrow?: boolean;
  borderRadius?: React.CSSProperties['borderRadius'];
  disabled?: boolean;
  loading?: boolean;
  icon?: JSX.Element;
  iconPosition?: IconPosition;
  darkIcon?: boolean;
  extraClasses?: string[];
}>;

export default function Button({
  theme,
  onClick = () => undefined,
  href,
  label,
  type = 'button',
  semiboldText = false,
  darkText = false,
  largeText = false,
  fullWidth = false,
  narrow = false,
  disabled = false,
  borderRadius = '0.25rem',
  loading = false,
  icon,
  extraClasses,
  iconPosition = IconPosition.RIGHT,
  darkIcon,
}: Props) {
  const classes = getClasses({
    'ds-button': true,
    [`ds-button--${theme}`]: true,
    'ds-button--full-width': fullWidth,
    'ds-button--loading': loading,
    'ds-button--semibold': semiboldText,
    'ds-button--dark': darkText,
    'ds-button--large': largeText,
    'ds-button--icon-only': Boolean(icon) && !label,
    'ds-button--with-icon': Boolean(icon && label),
    'ds-button--narrow': narrow,
    'ds-button--dark-icon': Boolean(darkIcon),
    ...(extraClasses?.reduce((acc, cur) => ({ ...acc, [cur]: true }), {}) ?? {}),
  });

  const contents =
    iconPosition === IconPosition.LEFT ? (
      <>
        {icon}
        <span className={`ds-button__label`}>{label}</span>
      </>
    ) : (
      <>
        <span className={`ds-button__label`}>{label}</span>
        {icon}
      </>
    );

  if (!label && !icon) return null;

  if (href) {
    return (
      <a className={classes} style={{ borderRadius: icon && !label ? '50%' : borderRadius }} href={href}>
        {loading ? (
          <Spinner theme={theme === Theme.ALL_PINK ? SpinnerTheme.Black : SpinnerTheme.Pink} />
        ) : label ? (
          contents
        ) : (
          icon
        )}
      </a>
    );
  }

  return (
    <button
      className={classes}
      style={{ borderRadius: icon && !label ? '50%' : borderRadius }}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
    >
      {loading ? (
        <Spinner theme={theme === Theme.ALL_PINK ? SpinnerTheme.Black : SpinnerTheme.Pink} />
      ) : label ? (
        contents
      ) : (
        icon
      )}
    </button>
  );
}

export { Button };
