import React from 'react';
import { Duration } from 'luxon';
import Avatar from '../avatar/';
import ExpertStatus from '../expertStatus';
import './profileSummaryTableCell.scss';

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

type Props = {
  firstName: string;
  highlightFirstName?: boolean;
  lastName: string;
  highlightLastName?: boolean;
  src?: string;
  avatarDiameter?: number;
  status?: Status;
  activeTime?: Duration;
  tag?: 'td' | 'div';
};

export default function ProfileSummaryTableCell({
  firstName,
  lastName,
  status,
  activeTime,
  src,
  avatarDiameter,
  highlightFirstName,
  highlightLastName,
  tag = 'td',
}: Props) {
  const Tag = tag;
  return (
    <Tag className="ds-profile-summary-td">
      <div className="ds-profile-summary-td__wrapper">
        {avatarDiameter && <Avatar firstName={firstName} lastName={lastName} src={src} diameter={avatarDiameter} />}
        <div className="ds-profile-summary-td__info">
          <p className="ds-profile-summary-td__name">
            {highlightFirstName ? <mark>{firstName}</mark> : firstName}{' '}
            {lastName && (highlightLastName ? <mark>{lastName}</mark> : lastName)}
          </p>
          {status && <ExpertStatus status={status} activeTime={activeTime} />}
        </div>
      </div>
    </Tag>
  );
}
