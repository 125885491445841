import React from 'react';
import StatsBlockLoading from './StatsBlockLoading';
import StatsBlockError from './StatsBlockError';
export { StatsBlockLoading, StatsBlockError };

import './statsBlock.scss';

export enum StatsBlockTheme {
  TEAL = 'teal',
  RED = 'red',
  GREY = 'grey',
  PEACH = 'peach',
}

type Props = {
  stats: React.ReactNode;
  label: string;
  theme: StatsBlockTheme;
  pulse?: boolean;
};

export function StatsBlock({ stats, label, theme, pulse = false }: Props) {
  return (
    <div className={`ds-stats-block ds-stats-block--${theme}`}>
      <span className="ds-stats-block__stats">{stats}</span>
      <span className="ds-stats-block__label">{label}</span>
      {pulse && <div className="ds-stats-block__pulse" />}
    </div>
  );
}
