import React from 'react';
import { CheckboxTree, CheckboxDataTree, CheckedState } from './types';

enum ActionType {
  CHECK = 'Check',
  UNCHECK = 'Uncheck',
}

type Action = {
  id: string | number;
  type: ActionType;
};

type SetState = React.Dispatch<React.SetStateAction<CheckedState>>;

function getNodeById(tree: CheckboxDataTree, id: number | string): CheckboxDataTree | null {
  if (tree.self.id == id) return tree;
  for (const child of tree.children as CheckboxDataTree[]) {
    const result = getNodeById(child, id);
    if (result) return result;
  }
  return null;
}

function getAllDescendantIds(node: CheckboxDataTree): (string | number)[] {
  const selfId = node.self.id;
  const childrenIds = (node.children ?? []).flatMap((child: CheckboxDataTree) => getAllDescendantIds(child));
  return [selfId, ...childrenIds];
}

function isLeaf(node: CheckboxDataTree) {
  return node.children.length === 0;
}

function checkAll(prevState: CheckedState, ids: (number | string)[]): CheckedState {
  // Imperative style for performance
  const newState = new Set(prevState);
  ids.forEach((id) => newState.add(id));
  return newState;
}

function uncheckAll(prevState: CheckedState, ids: (number | string)[]): CheckedState {
  // Imperative style for performance
  const newState = new Set(prevState);
  ids.forEach((id) => newState.delete(id));
  return newState;
}

function normalize(state: CheckedState, tree: CheckboxDataTree): CheckedState {
  if (isLeaf(tree)) {
    return state;
  }
  const childrenNormalizedState = tree.children.reduce(
    (prevState: CheckedState, child: CheckboxDataTree) => normalize(prevState, child),
    state
  );
  const childrenIds = tree.children.map((child: CheckboxDataTree) => child.self.id);
  const selfIsChecked = childrenIds.every((id: number | string) => childrenNormalizedState.has(id));

  const ret = new Set(childrenNormalizedState);
  if (selfIsChecked) {
    ret.add(tree.self.id);
  } else {
    ret.delete(tree.self.id);
  }

  return ret;
}

function applyChange(
  prevState: CheckedState,
  action: Action,
  // This is the complete tree from top to bottom. The normalize function needs it to compute the new state
  originalTree: CheckboxDataTree,
  // This is the tree that we are checking/unchecking. It's part of the original tree.
  treeToChange: CheckboxDataTree
) {
  const node = getNodeById(treeToChange, action.id);
  if (!node) return prevState;

  const descendantIds = getAllDescendantIds(node);
  // DenormalizedState has the selected checkbox and all its descendants checked/unchecked, but left the ancestors are untouched.
  let denormalizedState: CheckedState = new Set();
  if (action.type === ActionType.CHECK) {
    denormalizedState = checkAll(prevState, descendantIds);
  } else {
    denormalizedState = uncheckAll(prevState, descendantIds);
  }
  // NormalizedState sorted out the parents
  // normalize function needs the complete tree to compute the new state
  return normalize(denormalizedState, originalTree);
}

export function getOnChange(
  checked: boolean,
  setState: SetState,
  originalTree: CheckboxDataTree,
  treeToChange: CheckboxDataTree
) {
  const action: Action = {
    id: treeToChange.self.id,
    type: checked ? ActionType.UNCHECK : ActionType.CHECK,
  };

  return () => {
    setState((prev: CheckedState) => {
      return applyChange(prev, action, originalTree, treeToChange);
    });
  };
}

export function makeCheckboxNodes(
  state: CheckedState,
  setState: SetState,
  originalTree: CheckboxDataTree,
  currentTree: CheckboxDataTree
): CheckboxTree {
  const nodeId = currentTree.self.id;
  const checked = state.has(nodeId);
  let displayAsIndeterminate = false;
  let childrenNodes = [] as CheckboxTree[];
  if (currentTree.children.length) {
    childrenNodes = currentTree.children.map((child: CheckboxDataTree) =>
      makeCheckboxNodes(state, setState, originalTree, child)
    );
    const someChildrenChecked = childrenNodes.some(
      (child: CheckboxTree) => child.self.checked || child.self.displayAsIndeterminate
    );
    displayAsIndeterminate = !checked && someChildrenChecked;
  }

  return {
    self: {
      ...currentTree.self,
      checked,
      displayAsIndeterminate,
      onChange: getOnChange(checked, setState, originalTree, currentTree),
    },
    children: childrenNodes,
  };
}
