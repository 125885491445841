import React from 'react';
import { Duration } from 'luxon';
import { formatDuration } from '../../../helpers/formatDuration';
import './expertStatus.scss';

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

type Props = {
  status: Status;
  activeTime?: Duration;
};

export default function ExpertStatus({ status, activeTime }: Props) {
  let displayStatus: JSX.Element;

  if (status === Status.ACTIVE && activeTime) {
    const displayActiveTime = formatDuration(activeTime);
    displayStatus = (
      <span className="ds-expert-status">
        <span className="ds-expert-status--active">Active</span> for {displayActiveTime}
      </span>
    );
  } else if (status === Status.INACTIVE) {
    displayStatus = <span className="ds-expert-status ds-expert-status--inactive">Inactive</span>;
  } else {
    displayStatus = <span className="ds-expert-status ds-expert-status--archived">Archived</span>;
  }

  return displayStatus;
}
